.intro {
    background: url("https://images.unsplash.com/photo-1446292831938-bf9e56685fda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80");
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 20px 10px;
    position: relative;
    background-size: cover;
     ::after {
        // content: "";
        // position: absolute;
        // width: 100%;
        // height: 100%;
        // background: rgba(0, 0, 0, 0.201);
        // top: 0;
        // right: 0;
        // z-index: 3;
        // opacity: 0.5;
    }
}

.cta-call {
    box-shadow: rgba(248, 248, 248, 0.35) 0px 5px 15px;
}