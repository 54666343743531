.btn-primary{
  background-color: #1677ff !important;
  color: white !important;
}
.btn-warning{
  background-color: #ffc53d !important;
  color: white !important;
}
.btn-success{
  background-color: #40916c !important;
  color: white !important;
}
.btn-danger{
  background-color: #e35d6a !important;
  color: white !important;
}