.detail-img {
  width: 300px;
  height: 400px;
  object-fit: cover;
}

.detail-content {
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
