/* Start Footer */

.footer-section {
    background: #151421;
    border-top: 1px solid rgba(249, 249, 249, 0.1);
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.liveAnyway {
    position: relative;
     ::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 50%;
        background: black;
        top: 0;
        right: 0;
        z-index: 2;
    }
}

.footer .navbar-brand {
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    opacity: 1;
}

.navbar-brand .fa-align-right {
    color: #009f4d;
    margin-right: 5px;
    vertical-align: bottom;
}

.navbar-brand .logo {
    text-transform: none;
    display: block;
    font-size: 14px;
    padding-left: 46px;
    font-weight: 600;
    opacity: 0.9;
    color: white;
}

.navbar-brand p {
    font-size: 18px;
    line-height: 28px;
    color: #97a1a9;
}

.main-social-footer a {
    margin-right: 15px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    background: none;
    border-radius: 50%;
    transition: 0.3s;
    color: #97a1a9;
    opacity: 0.6s;
}

.footer-title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 25px;
    font-weight: 400;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.footer-title p {
    font-size: 28px;
    line-height: 28px;
    color: #97a1a9;
}

.footer-title ul,
li {
    list-style: none;
    margin-bottom: 10px;
}

.footer-section a {
    text-decoration: none;
    font-size: 18px;
    line-height: 28px;
    color: #97a1a9;
    opacity: 0.8;
}

.footer-section p {
    color: #97a1a9;
}


/* End Footer */