.cart {
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.1s ease-in-out;
    padding: 0.4rem;
    opacity: 1;
    border-radius: 0.2rem;
    &:hover {
        background-color: #7FA25C;
        opacity: 0.7;
    }
}

.ant-badge .ant-badge-count {
    min-width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 15px;
}

.ant-popover-title {}

.popover-card {}